@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulse-border {
  0%,
  100% {
    outline-color: transparent; /* Make it invisible initially */
    outline-width: 0;
    box-shadow: none;
  }
  50% {
    outline-width: 3px;
    outline-color: rgb(156, 163, 175);
    outline-style: solid; /* You need a style for outline to show */
  }
}

.input-error {
  animation: pulse-border 0.5s ease-in-out 2; /* Apply the new animation */
}
