.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dots-corners {
  position: relative;
}

.dots-corners::before,
.dots-corners::after,
.dots-corners > *::before,
.dots-corners > *::after {
  content: '';
  background: black;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%; /* Makes the dot circular */
}

/* Top-left corner */
.dots-corners::before {
  top: -4px; /* half the dot's size */
  left: -5px;
}

/* Top-right corner */
.dots-corners::after {
  top: -4px;
  right: -5px;
}

/* Bottom-left corner */
.dots-corners > *::before {
  bottom: -4px;
  left: -5px;
}

/* Bottom-right corner */
.dots-corners > *::after {
  bottom: -4px;
  right: -5px;
}

.ProseMirror:focus {
  outline: none;
}
